<template>
    <div>
        <Navbar page="Gestão de Cookies" link="/cookies" nameLink="Cookies" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div v-if="cookieBanner" class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-xl font-semibold mb-2">Informações sobre o site</h2>
                    <div class="bg-gray-100 px-2 py-5 rounded">
                        <h2 class="text-base font-semibold my-1">URL do site: <span class="font-normal">{{ cookieBanner.url }}</span></h2>
                        <h2 class="text-base font-semibold my-1">Impressões de página nos últimos 7 dias: <span class="font-normal">{{ historicoAcessos.acessos7dias }}</span></h2>
                        <h2 class="text-base font-semibold my-1">Impressões de página nos últimos 30 dias: <span class="font-normal">{{ historicoAcessos.acessos30dias }}</span></h2>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-xl font-semibold mb-2">Impressões por dia <small>(últimos 30 dias)</small></h2>
                    <h3>Período de {{dataCorte}} - {{dataFim}}</h3>
                    <apexchart v-if="grafico.data.length" height="240" type="line" :options="grafico.option" :series="grafico.data"></apexchart>
                </div>
                <div class="col-span-12 md:col-span-12">
                    <h2 class="text-xl font-semibold mb-2">Aceites vs Não aceites <small>(últimos 30 dias)</small></h2>
                    <h3>Período de {{dataCorte}} - {{dataFim}}</h3>

                    <table class="table-auto divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Interações com o cookie banner
                                </th>
                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Aceitaram todos
                                </th>
                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Rejeitaram todos
                                </th>
                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Personalizaram
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0">
                                    {{ totalInteracoes }}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {{ aceitaramTudo.total }}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {{ rejeitaramTudo.total }}
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {{ totalInteracoes - (aceitaramTudo.total + rejeitaramTudo.total) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="grid grid-cols-2 mt-5">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button"
                        class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        data() {
            return {
                versao: this.$store.state.empresa && this.$store.state.user.cookieBanner ? this.$store.state.user.stripe.plano : 'premium',
                route: 'cookies',
                cookieBanner: null,

                resumo: null,
                dataCorte: null,
                historicoAcessos: null,
                aceitaramTudo: 0,
                rejeitaramTudo: 0,
                totalInteracoes: 0,
                dataFim: moment().format("DD/MM/YYYY HH:mm"),

                grafico: {
                data: [],
                option: {
                    chart: {
                        type: "line",
                    },
                    xaxis: {
                        categories: [],
                    }
                }
            }
            }
        },
        methods: {
            
        },
        async beforeMount() {
            const id = this.$route.params.id;

            if (id) {
                const req = await this.$http.get(`/v1/${this.route}/detalhes/${id}`);
                this.cookieBanner = req.data.cookieBanner;

                this.historicoAcessos = req.data.historicoAcessos;
                this.resumo = req.data.resumo[0];
                this.aceitaramTudo = req.data.aceitaramTudo[0];
                this.rejeitaramTudo = req.data.rejeitaramTudo[0];
                this.totalInteracoes = req.data.totalInteracoes;
                this.dataCorte = moment(req.data.dataCorte).format("DD/MM/YYYY HH:mm");

                const reqGrafico = await this.$http.get(`/v1/${this.route}/grafico-acesso/${id}`);

                this.grafico.data = [{ name: 'Impressões', data: reqGrafico.data.acessosData }];
                this.grafico.option = this.grafico.option = {
                    chart: {
                        type: "line",
                    },
                    xaxis: {
                        categories: reqGrafico.data.labels,
                    },
                };
            }
        },
    }
</script>
